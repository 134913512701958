import React from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';

import UserMenu from './partials/UserMenu';

const { Header, Content, Footer } = Layout;

function AuthLayout({
  children
}) {
  return (
    <Layout>
      <Header className="bg-[url('../public/imgs/banner.png')] bg-cover h-[80px] sticky top-0 z-[100] shadow px-2 pr-5">
        <div className='container mx-auto flex items-center justify-between h-full'>
          <div className="demo-logo">
            <Link to="/dashboard" className="hidden sm:inline">
              <img src="/imgs/logo.png" alt="logo" className="h-[64px] p-3" />
            </Link>
            <Link to="/dashboard" className="inline sm:hidden">
              <img src="/imgs/logo-sm.png" alt="logo" className="w-[64px] p-3" />
            </Link>
          </div>
          <div className='flex items-center'>
            <div>
              <Link className='mr-3 sm:mr-5 md:mr-10 text-white drop-shadow-xl text-[13px] sm:text-[15px]' to={'/dashboard'}>Dashboard</Link>
              <Link className='mr-3 sm:mr-5 md:mr-10 text-white drop-shadow-xl text-[13px] sm:text-[15px]' to={'/pixel'}>Add Pixel</Link>
              {/* <Link className='mr-3 sm:mr-5 md:mr-10 text-white drop-shadow text-[13px] sm:text-[15px]' to={'/billings'}>Billing</Link> */}
              <Link className='mr-3 sm:mr-5 md:mr-10 text-white drop-shadow text-[13px] sm:text-[15px]' to={'/integrations'}>Integrations</Link>
            </div>
            <UserMenu />
          </div>
        </div>
      </Header>
      <Layout className='min-h-[calc(100vh_-_144.09px)] bg-gray-200'>
        <Content className='container mx-auto w-full px-4'>
          {children}
        </Content>
      </Layout>
      <Footer className='bg-primary text-white text-center'>
        Copyright © 2023 TrafficMagic.ai
      </Footer>
    </Layout >
  )
}

export default AuthLayout;