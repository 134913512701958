import React, {
  createContext,
  useContext,
  useRef,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import { message } from "antd";

import constants from "../config/constants";
import { getStorage } from "../helpers";
import { updateData } from "../redux/dashboard/dashboardSlice";
import { getPlans } from "../redux/plan/planSlice";

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const socket = useRef(null);

  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [isConnected, setConnected] = useState(false);
  useEffect(() => {
    if (isAuthenticated && !isConnected) {
      socket.current = io(constants.SOCKET_URL, {
        query: {
          token: getStorage("token"),
        },
      });

      socket.current.on("connect", () => {
        console.info(`connected`);
        setConnected(true);
      });

      socket.current.on("disconnect", () => {
        console.info(`disconnected`);
        setConnected(false);
      });

      socket.current.on("error", (err) => {
        console.log("Socket Error:", err.message);
      });

      socket.current.on("plan_change", (data) => {
        dispatch(getPlans());
      });

      socket.current.on("update_data", (data) => {
        dispatch(updateData(data));
      });

      socket.current.on("analyzed", (data) => {
        console.log(data);
      });
    }

    return () => {
      if (socket.current && socket.current.connected) {
        socket.current.disconnect();
      }
    };
  }, [isAuthenticated]);

  return (
    <SocketContext.Provider value={socket.current}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
