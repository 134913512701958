import { lazy } from 'react';

const routes = [
  {
    path: "pixel",
    component: lazy(() => import("../pages/Home/Home")),
    exact: true,
  },
  {
    path: "dashboard",
    component: lazy(() => import("../pages/Dashboard/Dashboard")),
    exact: true,
  },
  {
    path: "dashboard/:userId",
    component: lazy(() => import("../pages/Dashboard/Dashboard")),
    isAdmin: true,
    exact: true,
  },
  // {
  //   path: "billings",
  //   component: lazy(() => import("../pages/Billing/Billing")),
  //   exact: true,
  // },
  {
    path: "referrals/:userId?",
    component: lazy(() => import("../pages/Referrals")),
    exact: true,
  },
  {
    path: "user/profile",
    component: lazy(() => import("../pages/Profile/Edit")),
    exact: true,
  },
  {
    path: "dev/api/documentation",
    component: lazy(() => import("../pages/Documentation/Documentation")),
    exact: true,
  },
  // {
  //   path: "api-keys",
  //   component: lazy(() => import("../pages/APIkey/APIkey")),
  //   exact: true,
  // },
  {
    path: "integrations",
    component: lazy(() => import("../pages/Integrations/index")),
    exact: true,
  },
  {
    path: "integrations/:service",
    component: lazy(() => import("../pages/Integrations/index")),
    exact: true,
  },
  // {
  //   path: "integrations/klaviyo",
  //   component: lazy(() => import("../pages/Integrations/Klaviyo/_Klaviyo")),
  //   exact: true,
  // },
  {
    path: "admin/users",
    component: lazy(() => import("../pages/Admin/Users")),
    exact: true,
    isAdmin: true,
  },
];

export default routes;
