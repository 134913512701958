import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
// import jwt_decode from "jwt-decode";
// import { getStorage } from "../../helpers";

const initialState = {
  loading: false,
  records: [],
  totalCount: 0,
  totalSpent: 0,
  query: {
    startDate: dayjs(dayjs().format("YYYY-MM-DD")).format(
      "YYYY-MM-DDTHH:mm:ss.SSSZ"
    ),
    endDate: dayjs(dayjs().format("YYYY-MM-DD")).format(
      "YYYY-MM-DDTHH:mm:ss.SSSZ"
    ),
    page: 1,
    limit: 10,
  },
  errors: {},
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    getRecords(state) {
      state.loading = true;
      state.errors = {};
    },
    getRecordsSuccess(state, action) {
      state.loading = false;
      state.records = action.payload.records;
      state.totalCount = action.payload.totalCount;
      state.totalSpent = action.payload.totalSpent;
      state.query = action.payload.query;
      state.errors = {};
    },
    getRecordsFailure(state, action) {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    updateData(state, action) {
      // console.log(action.payload, '0-0-0-0-0');
      if (
        state.query.startDate === dayjs().format("YYYY-MM-DD") &&
        state.query.endDate === dayjs().format("YYYY-MM-DD")
      ) {
        // state.totalSpent = action.payload.totalSpent;
        // state.totalCount = action.payload.totalCount;
        }
    }
  },
});

export const { getRecords, getRecordsSuccess, getRecordsFailure, updateData } =
  dashboardSlice.actions;
export default dashboardSlice.reducer;
