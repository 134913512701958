export const setStorage = (key, value) => {
  if (value) {
    localStorage.setItem(key, value);
  } else {
    localStorage.removeItem(key);
  }
}

export const getStorage = (key) => {
  return localStorage.getItem(key);
}

export const getItem = (label, key, icon, children, type, disabled) => ({
  key,
  icon,
  children,
  label,
  type,
  disabled,
  title: label,
});

export const convertTimeZoneOffsetToTimeString = (value) => {
  let offsetMinutes = - Number(value)
  const hours = Math.floor(Math.abs(offsetMinutes) / 60);
  const minutes = Math.abs(offsetMinutes) % 60;
  const sign = offsetMinutes < 0 ? '-' : '+';

  return `${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}