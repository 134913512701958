import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, Dropdown, Image } from "antd";
import {
  LockOutlined,
  UserOutlined,
  AreaChartOutlined,
  SafetyOutlined,
  IdcardOutlined,
  DollarOutlined,
  HomeOutlined,
  DownOutlined,
  MailOutlined,
  PartitionOutlined,
  KeyOutlined,
} from "@ant-design/icons";

import { logout } from "../../../redux/auth/authSlice";

const UserMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const items = [
    // {
    //   label: "Add Pixel",
    //   key: "/pixel",
    //   icon: <HomeOutlined />,
    // },
    // {
    //   label: "Referrals",
    //   key: "/referrals",
    //   icon: <DollarOutlined />,
    // },
    {
      type: "group",
      label: user.name,
      children: [
        {
          label: "Profile",
          key: "/user/profile",
          icon: <IdcardOutlined />,
        },
        {
          label: "Contact Us",
          key: "support",
          icon: <MailOutlined />,
        },
        // {
        //   label: "API Key",
        //   key: "api-keys",
        //   icon: <KeyOutlined />,
        // },
        {
          key: "integrations",
          label: "Integrations",
          icon: <PartitionOutlined />,
          // children: [
          //   {
          //     label: "Klaviyo",
          //     key: "/integrations/klaviyo",
          //     icon: <Avatar size={20} src="/imgs/klaviyo.png" />,
          //   },
          // ],
        },
      ],
    },
    {
      label: "Admin",
      key: "admin",
      type: "group",
      icon: <SafetyOutlined />,
      children: [
        {
          label: "Users",
          key: "/admin/users",
          icon: <UserOutlined />,
        },
        // {
        //   label: "Analyze",
        //   key: "/admin/analyze",
        //   icon: <AreaChartOutlined />,
        // },
      ],
    },
    {
      label: "Log Out",
      key: "/auth/logout",
      icon: <LockOutlined />,
    },
  ];

  const handleClick = ({ item, key }) => {
    if (key === "/auth/logout") {
      dispatch(logout());
    } else if (key === "support") {
      window.open("mailto:hello@trafficmagic.ai", "_blank");
    } else {
      navigate(key);
    }
  };

  return (
    <Dropdown
      menu={{
        items: items.filter(
          (item) =>
            item.key !== "admin" || (item.key === "admin" && user.isAdmin)
        ),
        onClick: handleClick,
      }}
      trigger="click"
      placement="bottom"
      arrow
    >
      <Button
        className="flex items-center ml-auto"
        type="primary"
        icon={<UserOutlined />}
        title={user.name}
      >
        <span className="truncate max-w-[100px] text-[15px] !hidden sm:!inline-block">
          {user.name}
        </span>{" "}
        <DownOutlined className="!ml-0 sm:!ml-2" />
      </Button>
    </Dropdown>
  );
};

export default UserMenu;
