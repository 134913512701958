import { createSlice } from "@reduxjs/toolkit";
import { getStorage, setStorage } from "../../helpers";

const initialState = {
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        // setSelectedSource(state, action) {
            
        // },
    },
});

export const { setSelectedSource, setExQuiz, setOpenKeys, setUsersCount } = appSlice.actions;

export default appSlice.reducer;
