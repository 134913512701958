const constants = {
  HOST_URL: process.env.REACT_APP_HOST_URL || "/api/",
  // HOST_URL: process.env.REACT_APP_HOST_URL || 'http://46.175.146.14:5000/api/',
  // HOST_URL: process.env.REACT_APP_HOST_URL || 'http://127.0.0.1:5000/api/',
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  multipartHeaders: {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
  },
  stripePK:
    "pk_live_51Nqd37JA1crzgYiKhK1pa5W8Mg5ciXyo99BIpkJPUWZ5JhhENauUtJBgbwQO7LtLPEjzpHGHVPc8NioVtKgla7Wp00d1Ye6lAr",
  // stripePK:
  //  "pk_test_51MqDHeIhTKltFnGq3WGCHjZi5ZfUg5T8aiGVPa7XFZcrse4SGLvTjDbXDNb5inKjlOaJqHJRDv5HsPQ6tbnfsxRL00rwetwKzu",
  SOCKET_URL: "/",
  // SOCKET_URL: 'http://46.175.146.14:5000/',
  // SOCKET_URL: 'http://127.0.0.1:5000/',
};

export default constants;
