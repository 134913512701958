import { all, call, put, takeLatest } from "redux-saga/effects";
import { message } from "antd";

import { getRecords, getRecordsSuccess, getRecordsFailure } from "./dashboardSlice";

import { getMatchData } from "../../services/dashboardAPI";

// import { setStorage } from "../../helpers";

function* getRecordsAPI(action) {
  try {
    const response = yield call(() => getMatchData(action.payload));
    let data = response.data;
    yield put(getRecordsSuccess(data));
  } catch (e) {
    yield put(getRecordsFailure({}));
  }
}

export default function* rootSaga() {
  yield all([takeLatest(getRecords, getRecordsAPI)]);
}
